<template>
  <UaBigSelectInputSearch
    :class="[
      $style['ua-big-select'],
      testClass(name),
      { isDisabledClass : disabled },
    ]"
    :input-id="inputId"
    :disabled="disabled"
    :expanded="ddStore.expanded"
    :header-text="selectedText"
    :label="label"
    :name="`search-input-${name}`"
    :result-messages="resultMessages"
    :test-selector="testSelector"
    :theme="theme"
    @keydown.esc="setFocus('input')"
    @input-search-up-arrow="setFocus('last')"
    @input-search-down-arrow="setFocus('first')"
    @input-search-focus="setFocus('input')"
    @input-search-click="setFocus('input')"
    @input-search-change="setEmitInputChange($event)">
    <UaBigSelectListbox
      :id="ddStore.listboxID"
      :class="[
        $style['ua-big-select__listbox'],
        $style[`ua-big-select--${size}`],
        testClass(`${name}-listbox`),
      ]"
      :items="listData"
      :test-selector="testSelector"
      :theme="theme"
      @listbox-select="setFocus('input'); emitSelectionChange()" />
    <select
      :id="name"
      ref="hiddenSelect"
      :form="formName"
      :name="name"
      :value="ddStore.selectedIndex"
      style="display: none;">
      <option
        v-for="(option, index) in listData"
        :key="index"
        :value="option.value"
        :selected="ddStore.selectedIndex === index">
        {{ option.text }}
      </option>
    </select>
  </UaBigSelectInputSearch>
</template>

<script setup>
  import { computed, onMounted, provide, ref, watch } from 'vue';
  import { generateElementId, testClass } from 'music';
  import UaBigSelectInputSearch from
  '~/src/features/development/vite/design_system/vue_components/big_select/subcomponents/ua_big_select_input_search/UaBigSelectInputSearch.vue';
  import UaBigSelectListbox from
  '~/src/features/development/vite/design_system/vue_components/big_select/subcomponents/ua_big_select_listbox/UaBigSelectListbox.vue';

  /** @typedef {import('./types.js').ListboxItem} ListboxItem */
  /** @typedef {import('./types.js').ResultMessages} ResultMessages */
  const props = defineProps({
    inputId: {
      type: String,
      required: true,
    },
    disabled: { type: Boolean, default: false },
    name: {
      type: String,
      default: 'ua-big-select',
    },
    formName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    /** @type {ListboxItem[]} */
    listData: {
      type: Array,
      default: null,
    },
    /** @type {ResultMessages{}} */
    resultMessages: {
      type: Object,
      default: null,
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['md', 'sm'].includes(value);
      },
    },
    testSelector: {
      type: String,
      default: 'ua-big-select',
    },
    theme: {
      type: String,
      default: 'm4',
    },
  });

  const emit = defineEmits(['bigselectchange', 'big_select_input_change']);
  const hiddenSelect = ref(null);

  const isDisabledClass = 'is-disabled';

  const ddStore = ref({
    inputSearchId: props.inputId,
    expanded: true,
    focusedIndex: -1,
    selectedIndex: -1,
  });

  provide('dropdownStore', ddStore);

  const selectedText = computed(() => {
    if (
      ddStore.value &&
      ddStore.value?.selectedIndex >= 0
    ) {
      return props.listData[ddStore.value.selectedIndex].text;
    } else {
      return '';
    }
  });

  const setFocus = (index) => {
    let newIndex;
    if (index === 'first') {
      newIndex = 0;
    } else if (index === 'last') {
      newIndex = props.listData.length - 1;
    } else if (index === 'input') {
      newIndex = -1;
    }
    ddStore.value.focusedIndex = newIndex;
  };

  const setEmitInputChange = (eventData) => {
    // Event to send input vaue change.
    emit('big_select_input_change', eventData);
    const changed = new CustomEvent('big_select_input_change', {
      detail: eventData,
    });
    document.dispatchEvent(changed);
  }

  const emitSelectionChange = () => {
    // Event to send selected data.
    const changed = new CustomEvent('big_select_option_change', {
      detail: {
        selectedData: JSON.stringify(props.listData[ddStore.value.selectedIndex])
      }
    });
    document.dispatchEvent(changed);
    emit('big_select_option_change', JSON.stringify(props.listData[ddStore.value.selectedIndex]));
  };

  watch(
    () => props.listData,
    async (newList, oldList) => {
      if (newList != oldList) {
        ddStore.value.selectedIndex = -1;
        ddStore.value.focusedIndex = -1;
      }
    }
  );
  
  onMounted(async() => {
    ddStore.value.expanded = true;
    ddStore.value.selectedIndex = props.selectedIndex;
    ddStore.value.focusedIndex = -1;
    ddStore.value.listboxId = generateElementId('listbox');
  });

</script>

<style lang="scss" module>
  @use 'music/app/styles/library/base' as * ;

  .ua-big-select {
    display: inline-block;
    font-weight: normal;
    position: relative;
    width: 100%;

    &.is-disabled {
      border-color: var(--m4-role-disabled, $disabled-color);
    }

    &__listbox {
      border-radius: 1.5rem;
      background-color: var(--m4-util-white);
      margin-top: 1rem;
      padding: 1rem;

    }

    .ua-big-select--sm {
      height: rpx(234);
    }

    .ua-big-select--md {
      height: rpx(280);
    }
  }
</style>
