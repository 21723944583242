<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    :class="[
      $style['c-big-select-input-search'],
      testClass('big-select-input-search'),
      $style[`theme-${theme}`],
    ]">
    <l-line align-x="between">
      <ua-text-field :class="$style['search-text-field']" theme="m4">
        <music-icon-m4-search></music-icon-m4-search>
        <label :for="inputId">{{ label }}</label>
        <input
          :id="inputId"
          ref="inputSearchInput"
          v-model="headerLabel"
          type="text"
          autocomplete="off"
          :class="[
            $style['c-big-select-input-search__input'],
            testClass('big-select-input-search-input'),
            testClass(`input-${testSelector}`),
          ]"
          :disabled="disabled"
          :aria-controls="ddStore.listboxId"
          :aria-expanded="expanded"
          :aria-label="(ddStore.selectedIndex === -1 ) ? 'blank' : null"
          :name="name"
          @keydown.up.prevent="$emit('inputSearchUpArrow')"
          @keydown.down.prevent="$emit('inputSearchDownArrow')"
          @click.stop="$emit('inputSearchClick');"
          @focus="$emit('inputSearchFocus')"
          @keydown.enter.stop.prevent="handleInput($event.target.value)">
      </ua-text-field>
      <button 
        type="button"
        class="m4-button  m4-button--tertiary"
        @click.prevent="handleInput(headerLabel)">
        Search
      </button>
    </l-line>
    <div
      :class="$style['ua-school-search-feedback']">
      <div 
        v-if="resultMessages?.unrecognizedTerms?.length > 0"
        :class="$style['ua-school-search-feedback__unrecognized']">
        <span
          :class="$style['ua-school-search-feedback__unrecognized-label']">
          Unrecognized Words:
        </span>
        <span
          :class="[$style['ua-school-search-feedback__unrecognized-word'], testClass('unrecognized-terms')]">
          <span
            v-for="unrecognizedTerm in resultMessages?.unrecognizedTerms"
            :key="unrecognizedTerm"
            :class="testClass('unrecognized-word')">
            <span v-html="unrecognizedTerm" />
          </span>
        </span>
      </div>
      <p
        v-if="resultMessages?.message"
        :class="[$style['ua-school-search-feedback__message'], testClass('result-msg')]"
        v-html="resultMessages?.message" />
      <ul
        v-if="resultMessages.suggestions?.length > 0"
        :class="[$style['ua-school-search-feedback__list'], testClass('search-suggestions')]">
        <li
          v-for="suggestion in resultMessages?.suggestions"
          :key="suggestion"
          class="u-dis-flex">
          <music-icon-m4-check-square></music-icon-m4-check-square>
          <span>{{ suggestion }}</span>
        </li>
      </ul>
    </div>
    <div
      :class="[
        $style['c-big-select-input-search__body'],
        testClass('c-big-select-input-search-body')]">
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { computed, inject, nextTick, ref, watch } from 'vue';
  import { testClass, validateEnum } from 'music';

  /** @typedef {import('../../types.js').ResultMessages} ResultMessages */
  const props = defineProps({
    inputId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    /** @type {ResultMessages{}} */
    resultMessages: {
      type: Object,
      default: null,
    },
    testSelector: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'm4',
      validate: (val) => validateEnum(val, 'theme'),
    },
  });

  const emit = defineEmits([
    'inputSearchClick',
    'inputSearchFocus',
    'inputSearchUpArrow',
    'inputSearchDownArrow',
    'inputSearchChange',
  ]);

  const ddStore = inject('dropdownStore');
  const inputSearchInput = ref(null);

  watch(
    () => ddStore.value.focusedIndex,
    async (newIndex) => {
      if (newIndex === -1) {
        await nextTick();
        inputSearchInput.value.focus();
      }
    }
  );

  watch(
    () => props.headerText,
    async (newText, oldText) => {
      if (newText != oldText) {
        headerLabel.value = newText;
      }
    }
  );

  const headerLabel = ref(props.headerText);

  const empty = computed(() => {
    return ddStore.value.selectedIndex < 0;
  });

  const handleInput = (text) => {
    const inputText = text;
    headerLabel.value = inputText;
    emit('inputSearchChange', inputText);
  };
</script>

<style lang="scss" module>
  @use 'music/app/styles/library/base' as *;
  @use '../../themes/input_search';

  .c-big-select-input-search {
    border-width: 0;
    display: block;
    line-height: 1.5;
    position: relative;

    music-icon-m4-search svg {
      fill: var(--input-search-icon-fill-color);
    }
    
    .search-text-field {
      width: 100%;
    }

    &__input {
      font-weight: var(--m4-font-semibold, 600);
      width: 100%;
    }

    &__body {
      padding: 0;
    }
  }

  .ua-school-search-feedback {
    align-self: flex-start;
    color: var(--m4-true-gray-600, #595959);
    margin: 0 2rem;

    &__list {
      @include plain-list();
      margin-top: 0;
    }

    &__message {
      margin-top: 0.5rem;
      font-weight: var(--m4-font-semibold);
    }

    &__unrecognized {
      padding: 0.5rem 0;
      border-bottom: rpx(2) solid var(--m4-true-gray-200);
    }

    &__unrecognized-label {
      color: var(--m4-role-error, #D71000);
      font-weight: var(--m4-font-semibold);
    }

    &__unrecognized-word { 
      color: var(--m4-true-gray-900, #1D1D1D);
      font-weight: var(--m4-font-semibold);
      font-style: italic;
    }
  }

</style>
