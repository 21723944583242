<template>
  <div
    :class="[
      $style['c-listbox'], 
      $style[`theme-${theme}`] ]"
    aria-relevant="additions"
    aria-live="assertive"
    aria-atomic="true">
    <ul
      :id="ddStore.listboxId"
      ref="listboxList"
      :class="[
        $style['c-listbox__list'],
        'm4-scrollbar',
        'm4-scrollbar-fade-effect',
        testClass('big-select-listbox'),
        testClass(`listbox-${testSelector}`),
      ]"
      aria-orientation="vertical"
      lang="en"
      role="list">
      <li
        v-for="(item, index) in items"
        :key="index"
        role="button"
        :aria-selected="ddStore.selectedIndex === index"
        :class="[
          item.classes,
          $style['c-listbox__item'],
          { 'is-focused': ddStore.focusedIndex === index },
          testClass(`list-item-${testSelector}`)
        ]"
        :data-index="index"
        :value="item.value"
        :tabindex="(lastFocused === index) ? 0 : -1"
        @keydown="handleItemKeyDown($event, index)"
        @click="selectOption($event, index)"
        @focus="ddStore.focusedIndex = index; lastFocused = index;">
        <span
          v-html="item.text" />
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { inject, nextTick, ref, watch } from 'vue';
  import { testClass, validateEnum } from 'music';

  /** @typedef {import('../types.js').ListboxItem} ListboxItem */
  /** @typedef {import('./types.js').ResultMessages} ResultMessages */
  const props = defineProps({
    /**
     * @type {ListboxItem[]} items
     */
    items: {
      type: Array,
      default: () => ([
      ]),
    },
    testSelector: {
      type: String,
    },
    theme: {
      type: String,
      default: 'supersite',
      validate: (val) => validateEnum(val, 'theme'),
    },
  });

  const emit = defineEmits([
    'listboxSelect',
  ]);

  const ddStore = inject('dropdownStore');
  const listboxList = ref(null);
  const lastFocused = ref(0);

  watch(
    () => ddStore.value.focusedIndex,
    async (newIndex, oldIndex) => {
      if (newIndex >= 0) {
        if (listboxList.value) {
          // If the dropdown is just opening, wait til it's rendered:
          await nextTick();
          listboxList.value.children[newIndex].focus();
        }
      }
    }
  );

  const setFocus = async (index) => {
    ddStore.value.focusedIndex = index;
  };

  const selectOption = (event, index) => {
    ddStore.value.selectedIndex = index;
    event.preventDefault();
    emit('listboxSelect');
  };

  const handleItemKeyDown = (event, index) => {
    const key = event.key;
    let newIndex;

    if (key === 'ArrowDown') {
      newIndex = index + 1;
      if (newIndex >= props.items.length) newIndex = 0;
      event.preventDefault();
      setFocus(newIndex);
    }

    if (key === 'ArrowUp') {
      newIndex = index - 1;
      if (newIndex < 0) newIndex = props.items.length - 1;
      event.preventDefault();
      setFocus(newIndex);
    }

    if (key === 'Enter' || key === ' ') {
      selectOption(event, index);
    }
  };
</script>

<style lang="scss" module>
@use 'music/app/styles/library/base' as *;
@use '../../themes/listbox';


.c-listbox {
  display: block;
  
  &__list {
    @include plain-list();

    height: 100%;
    margin: 0;
    padding-right: 2rem; 
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  &__item {
    color: var(--listbox-option-text-color, #344054);
    background-color: var(--listbox-option-fill-color, $white);
    cursor: pointer;
    display: flex;
    font-weight: normal;
    gap: 0.25rem;
    align-items: center;
    list-style-type: none !important;
    margin: rpx(2);
    padding: var(--listbox-option-padding, 0.75rem);
    text-align: left;
    font-weight: var(--m4-font-semibold, 600);
    color: var(--listbox-option-selected-text-color, #1D1D1D);
    border: var(--listbox-option-edge, 0);

    > * {
      line-height: 1.2;
    }

    &:last-child {
      border-bottom: 0;
      border-bottom-left-radius: var(--listbox-option-hover-border-radius, 1.5rem);
      border-bottom-right-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    }

    &:first-child{
      border-top-left-radius: var(--listbox-option-hover-border-radius, 1.5rem);
      border-top-right-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    } 

    &[aria-selected="true"] {
      color: var(--listbox-option-selected-text-color, #1D1D1D);
      background-color: var(--listbox-option-selected-fill-color, #F2F2F2);
      border: var(--listbox-option-selected-edge, 0);
      border-radius: 1.5rem;
    }

    &:hover {
      color: var(--listbox-option-hover-text-color, #1D1D1D);
      background-color: var(--listbox-option-hover-fill-color, #F2F2F2);
      border-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    }

    &:focus,
    &.is-focused {
      color: var(--listbox-option-focus-text-color, $focus-ring-color);
      @include focus-standard();
      outline-offset: 0 !important;
      border-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    }
  }
}
</style>

